import { HTMLAttributes } from 'react';
import { Controller, Control, RegisterOptions } from 'react-hook-form';

interface InputFieldProps {
	classNameContainer?: HTMLAttributes<HTMLInputElement>['className'];
	classExtraName?: HTMLAttributes<HTMLInputElement>['className'];
	name: string;
	control: Control<any, unknown>;
	rules?: RegisterOptions;
	autoComplete?: string | '' | 'off' | 'on';
	type?: 'text' | 'password' | 'email' | 'number' | 'tel' | 'url' | 'search';
	placeholder?: string;
	hidden?: boolean;
}

const preventInvalidChars = (e: React.FormEvent<HTMLInputElement>, type: any ) => {
	if (type == "number") {
		const target = e.target as HTMLInputElement;
		target.value = target.value.replace(/[eE]/g, ''); // Reemplaza 'e' o 'E'
	}
};

export default function InputField({
	classNameContainer,
	classExtraName,
	name,
	control,
	rules,
	autoComplete = 'off',
	type,
	placeholder = '',
	hidden = false
}: InputFieldProps): JSX.Element {
	return (
		<div className={`relative  ${classNameContainer}`}>
			<Controller
				name={name}
				control={control}
				rules={rules}
				render={({ field, fieldState: { error } }) => (
					<>
						<input
							//className="peer input input-bordered rounded-full h-10 w-full focus:placeholder-transparent border-[3px] border-[#c3c3c3]"
							className={` ${classExtraName} peer input input-bordered h-10 w-full focus:placeholder-transparent border-[3px] border-[#c3c3c3]  ${classExtraName}`}
							{...field}
							value={field.value || ''}
							id={name}
							autoComplete={autoComplete}
							type={type}
							name={name}
							placeholder={placeholder}
							hidden={hidden}
							onInput={(e) => preventInvalidChars(e, type)}
						/>
						{/*<label
							htmlFor={name}
							className="absolute -top-6 left-0 ml-4 select-none px-1 text-sm font-semibold text-[#b1b9c1] transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-focus:-left-2 peer-focus:-top-6 peer-focus:ml-4 peer-focus:text-sm peer-focus:font-semibold peer-focus:text-sky-500 peer-active:text-[#b1b9c1] peer-enabled:-left-2">
							{placeholder}
						</label>*/}
						{error && (
							<span className="mt-2 text-sm text-red-600">
								{error?.message}
							</span>
						)}
					</>
				)}
			/>
		</div>
	);
}
