import { useEffect, useState } from "react"
import { useForm } from 'react-hook-form';
import InputField from "../../../components/form/InputField";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import ryderLogo from '../../../assets/logos/ryder_health_logo.png';
import instagramLogo from '../../../assets/logos/instagram_logo.png';
import facebookLogo from '../../../assets/logos/facebook_logo.png';
import { useNavigate } from "react-router-dom";
import InputFieldReactSelect from '../../../components/react-hook-form/inputs/InputFieldReactSelect';
import ModalWithoutX from "../../../components/global/ModalWithoutX";
import { CodesToCreate, PlanData } from "../utils/types";
import { useAppDispatch, useAppSelector } from "../../../app/hookRTK";
import { setPlanData, clearPlanData } from "../app/addServiceSlice";
import formatCurrencyToDecimal from "../../../utils/formatCurrencyToDecimal";
import { useCreateAdminCodesMutation, useGetAllCategoriesMutation } from "../app/adminCodeService";
import ClassicButton from "../../../components/buttons/ClassicButton";

export const AddService = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const PlanDataPreview = useAppSelector((state) => state.planDataSlice);

    const {
        control,
        handleSubmit,
        reset: resetUseForm,
        // getValues,
        formState: { isValid },
    } = useForm<PlanData>({
        mode: 'all',
    });

    	const [
		createAdminCodeService,
		// {
		// 	isLoading: createAdminCodeIsLoading,
		// 	isError: createAdminCodeIsError,
		// 	isSuccess: createAdminCodeIsSuccess,
		// 	error: createAdminCodeError,
		// 	reset: createAdminCodeReset,
		// },
        ] = useCreateAdminCodesMutation();
    
    const [getCodeCategories] = useGetAllCategoriesMutation();

    /* const [ categoryOptions ] = useState([
        { value: 'Ambulatorio', label: 'Ambulatorio' },
        { value: 'Hospitalización', label: 'Hospitalización' },
        { value: 'Emergencia', label: 'Emergencia' },
    ]); */

    /* const [ feeOptions ] = useState([
        { value: '5%', label: '5%' },
        { value: '10%', label: '10%' },
        { value: '20%', label: '20%' },
    ]); */

    const [ showConfirmModal, setShowConfirmModal ] = useState<boolean>(true);
    const [ showSuccessModal, setShowSuccessModal ] = useState<boolean>(false);
    const [ createIsError, setCreateIsError ] = useState<boolean>(false);
    const [categories, setCategories] = useState<any[]>([]);
    const [existsOtherCategory, setExistsOtherCategory] = useState<boolean>(false);

    const getAllCategories = async () => {
        try {
            const categories: any = await getCodeCategories('').unwrap();
            const updatedCategories = [...categories.data, "Otro"];
            setCategories(updatedCategories);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getAllCategories();
    }, []);

    const categoryOptions = categories.map((category) => ({
        label: category,
        value: category,
    }));

    const add = async () => {
        console.log('Servicio creado');
        let categoryValue: string | null | undefined;

        if (existsOtherCategory) {
            categoryValue = PlanDataPreview?.otherCategory;
        } else {
            categoryValue = PlanDataPreview?.category?.value;
        }

        const dataFormated: CodesToCreate  = {
            code: PlanDataPreview?.code,
            description: PlanDataPreview?.description,
            price: PlanDataPreview?.fee,
            category: categoryValue,
            //category: PlanDataPreview?.category?.value,
            /* 
            medical_plans: [
                {
                    mdp_id: "mplan_wk20vd12fh8kmnguytrexzsv",
                    levels: [
                        {
                            id: "level_lcw5eu86yxeikvszvmegtmhc",
                            copago: PlanDataPreview?.goldPlanLevelOneCopayment,
                            deductible: PlanDataPreview?.goldPlanLevelOneCoinsured
                        },
                        {
                            id: "level_mdx6fv97zyfjlwtawnfhuoid",
                            copago: PlanDataPreview?.goldPlanLevelTwoCopayment,
                            deductible: PlanDataPreview?.goldPlanLevelTwoCoinsured
                        }
                    ]
                },
                {
                    mdp_id: "mplan_xl31we23gi9lnohvzusfyatw",
                    levels: [
                        {
                            id: "level_mey7gw08azgkmxubxmgivpje",
                            copago: PlanDataPreview?.platinumPlanLevelOneCopayment,
                            deductible: PlanDataPreview?.platinumPlanLevelOneCoinsured
                        },
                        {
                            id: "level_nfz8hx19bahlnyvcynhjwqkf",
                            copago: PlanDataPreview?.platinumPlanLevelTwoCopayment,
                            deductible: PlanDataPreview?.platinumPlanLevelTwoCoinsured
                        }
                    ]
                }
            ]
            */
            medical_plans: [
                {
                    mdp_id: "mplan_xl31we23gi9lnohvzusfyatw",
                    levels: [
                        {
                            id: "level_mey7gw08azgkmxubxmgivpje",
                            copago: PlanDataPreview?.goldPlanLevelOneCopayment,
                            deductible: PlanDataPreview?.goldPlanLevelOneCoinsured
                        },
                        {
                            id: "level_nfz8hx19bahlnyvcynhjwqkf",
                            copago: PlanDataPreview?.goldPlanLevelTwoCopayment,
                            deductible: PlanDataPreview?.goldPlanLevelTwoCoinsured
                        }
                    ]
                },
                {
                    mdp_id: "mplan_wk20vd12fh8kmnguytrexzsv",
                    levels: [
                        {
                            id: "level_dak5xc92sftybnuiqwzasngx",
                            copago: PlanDataPreview?.platinumPlanLevelOneCopayment,
                            deductible: PlanDataPreview?.platinumPlanLevelOneCoinsured
                        },
                        {
                            id: "level_ebs6qc45dvbetysdxjvrdfeq",
                            copago: PlanDataPreview?.platinumPlanLevelTwoCopayment,
                            deductible: PlanDataPreview?.platinumPlanLevelTwoCoinsured
                        }
                    ]
                }
            ]
        }

        console.log(dataFormated)
        if (PlanDataPreview && dataFormated) {

            await createAdminCodeService(dataFormated)
                .unwrap()
                .then((res) => {
                    console.log(res);
                    setShowSuccessModal(true);
                    setCreateIsError(false);
                    setShowConfirmModal(false);
                    //resetUseForm();
                    resetUseForm({
                        code: '',
                        description: '',
                        fee: '',
                        category: null,
                        otherCategory: '',
                        goldPlanLevelOneCopayment: '',
                        goldPlanLevelTwoCopayment: '',
                        platinumPlanLevelOneCopayment: '',
                        platinumPlanLevelTwoCopayment: '',
                        goldPlanLevelOneCoinsured: '',
                        goldPlanLevelTwoCoinsured: '',
                        platinumPlanLevelOneCoinsured: '',
                        platinumPlanLevelTwoCoinsured: '',
                    });
                    dispatch(clearPlanData());
                }).catch((err) => {
                    console.log("error", err);
                    // setShowSuccessModal(false);
                    // setCreateIsError(true);
                    // setShowConfirmModal(false);
                }
            );
        }

        // setShowSuccessModal(true);
        // setCreateIsError(true);
        //setShowConfirmModal(true); por ahora
    }

    const handleClick = () => {
        navigate('/home');
    };

    const addNewService = ({ ...ars }) => {
        dispatch(setPlanData(ars));
        setShowConfirmModal(false);
    };

    const modalBody = ({
        code,
        category,
        otherCategory,
        description,
        fee,
        goldPlanLevelOneCopayment,
        goldPlanLevelTwoCopayment,
        goldPlanLevelOneCoinsured,
        goldPlanLevelTwoCoinsured,
        platinumPlanLevelOneCopayment,
        platinumPlanLevelTwoCopayment,
        platinumPlanLevelOneCoinsured,
        platinumPlanLevelTwoCoinsured,
    }:PlanData) => {

        const goldPlanLevelOneCopaymentValue = formatCurrencyToDecimal(Number(goldPlanLevelOneCopayment));
        const goldPlanLevelTwoCopaymentValue = formatCurrencyToDecimal(Number(goldPlanLevelTwoCopayment));
        const platinumPlanLevelOneCopaymentValue = formatCurrencyToDecimal(Number(platinumPlanLevelOneCopayment));
        const platinumPlanLevelTwoCopaymentValue = formatCurrencyToDecimal(Number(platinumPlanLevelTwoCopayment))

        if (createIsError) {
            return (
                <div className="text-2xl font-medium text-center py-4 rounded-lg">
                    Ha ocurrido un error
                </div>
            );
        }

        if (showSuccessModal) {
            return (
                <div className="text-2xl font-medium text-center py-4 rounded-lg">
                    Código o servicio creado exitosamente
                </div>
            );
        }

        return (
            <div className="bg-white w-full pb-6 rounded-[2.5rem]">
                <div className="grid grid-cols-8 gap-y-4 mt-4 mb-10">
                    <div className="col-start-1 col-span-4 flex justify-end gap-x-2">
                        <div className="font-bold text-lg">Código:</div>
                        <div className="text-lg mr-4">{code}</div>
                    </div>
                    <div className="col-start-5 col-span-4 flex justify-start gap-x-2">
                        <div className="font-bold text-lg">Categoría:</div>
                        {
                            existsOtherCategory ?
                                <div className="text-lg">{otherCategory && otherCategory}</div>
                            :
                                <div className="text-lg">{category && category?.label}</div>
                        }
                    </div>
                    <div className="col-start-1 col-span-4 flex justify-end gap-x-2">
                        <div className="font-bold text-lg">Tarifa:</div>
                        <div className="text-lg mr-4">{fee}</div>
                    </div>
                    <div className="col-start-5 col-span-4 flex justify-start gap-x-2">
                        <div className="font-bold text-lg">Descripción:</div>
                        <div className="text-lg">{description}</div>
                    </div>
                </div>
                <div className="grid grid-cols-8 px-10">
                    <div className="col-start-1 col-span-4">
                        <div className="font-bold text-lg text-center pb-6 border-solid border-r-2 border-gray-600">Plan: ORO</div>
                        <div className="flex justify-evenly border-solid border-r-2 border-gray-600">
                            <div className="font-bold text-lg mx-auto mb-4 text-secondary">Nivel 1</div>
                            <div className="font-bold text-lg mx-auto mb-4 text-secondary">Nivel 2</div>
                        </div>
                        <div className="font-bold text-md text-center pb-4 border-solid border-r-2 border-gray-600">Copago $</div>
                        <div className="flex border-solid border-r-2 border-gray-600">
                            <div className="mx-auto">{goldPlanLevelOneCopaymentValue}</div>
                            <div className="mx-auto">{goldPlanLevelTwoCopaymentValue}</div>
                        </div>
                        <div className="py-6 px-7 border-solid border-r-2 border-gray-600">
                            <hr className="bg-gray-600 h-1" />
                        </div>
                        <div className="font-bold text-md text-center pb-4 border-solid border-r-2 border-gray-600">Coaseguro %</div>
                        <div className="flex border-solid border-r-2 border-gray-600">
                            <div className="mx-auto">{goldPlanLevelOneCoinsured}%</div>
                            <div className="mx-auto">{goldPlanLevelTwoCoinsured}%</div>
                        </div>
                    </div>
                    <div className="col-start-5 col-span-4">
                        <div className="font-bold text-lg text-center pb-6">Plan: PLATINO</div>
                        <div className="flex justify-evenly">
                            <div className="font-bold text-lg mx-auto mb-4 text-secondary">Nivel 1</div>
                            <div className="font-bold text-lg mx-auto mb-4 text-secondary">Nivel 2</div>
                        </div>
                        <div className="font-bold text-md text-center pb-4">Copago $</div>
                        <div className="flex">
                            <div className="mx-auto">{platinumPlanLevelOneCopaymentValue}</div>
                            <div className="mx-auto">{platinumPlanLevelTwoCopaymentValue}</div>
                        </div>
                        <div className="py-6 px-7">
                            <hr className="bg-gray-600 h-1" />
                        </div>
                        <div className="font-bold text-md text-center pb-4">Coaseguro %</div>
                        <div className="flex">
                            <div className="mx-auto">{platinumPlanLevelOneCoinsured}%</div>
                            <div className="mx-auto">{platinumPlanLevelTwoCoinsured}%</div>
                        </div>
                    </div>
                </div>
            </div>
        )

    };

    const handleBackButton = () => {
        navigate('/services-maintenance');
    };

    return (
        <>
            {!showConfirmModal && PlanDataPreview && (
                <ModalWithoutX
                    ExtraClassNameForTitle="text-secondary"
                    ExtraClassName="w-[50rem]"
                    title={showSuccessModal ? 'Éxito' : createIsError ? 'Aviso' : '¿Estás seguro de crear el siguiente código?'}
                    setDispatch={setShowConfirmModal}
                    children={modalBody(PlanDataPreview)}
                    buttons={[
                        {
                            classNameContainer: 'w-fit sm:w-60 rounded-[0.5rem] text-white text-xl font-bold bg-[#717477] hover:bg-[#5C5F61]',
                            isSubmit: false,
                            label: ('Volver'),
                            showButton: (() => {
                                if (createIsError || showSuccessModal) {
                                    return true
                                } else {
                                    return false
                                }
                            })(),
                            onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowSuccessModal(false);
                                setShowConfirmModal(true);
                                if (existsOtherCategory) {
                                    setCategories([]);
                                    getAllCategories();
                                    setExistsOtherCategory(false);
                                }
                            },
                        },
                        {
                            classNameContainer: 'sm:w-60 rounded-[0.5rem] text-white text-xl font-bold bg-[#717477] hover:bg-[#5C5F61]',
                            isSubmit: false,
                            label: ('Volver'),
                            showButton: (() => {
                                if (!createIsError && !showSuccessModal) {
                                    return true
                                } else {
                                    return false
                                }
                            })(),
                            onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowConfirmModal(true);
                            },
                        },
                        {
                            classNameContainer: 'sm:w-60 rounded-[0.5rem] text-xl font-bold bg-secondary hover:bg-[#3E8B34]',
                            isSubmit: true,
                            label: ('Aceptar'),
                            showButton: (() => {
                                if (!createIsError && !showSuccessModal) {
                                    return true
                                } else {
                                    return false
                                }
                            })(),
                            onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                add();
                            },
                        },
                    ]}
                />
            )}
            <div className="">
                {/*<div className="bg-primary py-4 text-white text-lg mb-4">
                    <div className="mx-auto flex max-w-6xl items-center justify-between">
                        <div>
                            <span className="mr-2">Suscríbete a Plan Ryder Hoy</span>
                            <span className="ml-2">Sobre Nosotros</span>
                        </div>
                        <div>
                            <span className="mr-2">
                                <MdOutlinePhoneInTalk className="inline w-5 h-5" />
                                (787)988-7910
                            </span>
                            <span>|</span>
                            <span className="ml-2">(787)852-0846</span>
                        </div>
                    </div>
                </div>
                <div className="mx-auto flex max-w-6xl items-center justify-between">
                    <div>
                        <img className="w-32 h-24 cursor-pointer" src={ryderLogo} alt="Ryder Logo" onClick={handleClick} />
                    </div>
                    <div className="flex items-center justify-end flex-2">
                        <a href="https://www.facebook.com/PlanRyder/" target="_blank">
                            <img className="w-9 h-9" src={facebookLogo} alt="Facebook Logo" />
                        </a>
                        <a href="https://www.instagram.com/PlanRyder/" target="_blank">
                            <img className="w-9 h-9" src={instagramLogo} alt="Instagram Logo" />
                        </a>
                    </div>
                </div>
                <hr className="bg-secondary mt-6 " style={{ height: '3.5px' }} />*/}
                <div className="bg-[#eff0ee]">
                    <div className="mx-auto grid max-w-3xl md:grid-cols-3 pt-14">
                        <div className="col-span-3 text-center">
                            <h2 className="text-3xl font-bold text-primary mb-6">
                                Crear nuevo código o servicio
                            </h2>
                            <h3 className="text-xl font-medium text-gray mb-5 ">
                                Llena el formulario con la información requerida para crear un nuevo código
                                y servicio de salud.
                            </h3>
                        </div>
                    </div>
                    <form
                        id="addService"
                        onSubmit={handleSubmit(addNewService)}
                        className="bg-white mx-auto max-w-6xl pb-14 rounded-[2.5rem] shadow-md border-[2px] border-[#e4e4e4]"
                    >
                        <div className="grid grid-cols-8  gap-y-4 mt-14 mb-10 px-10">
                            <div className="col-start-1 col-span-4 flex justify-start"
                                style={{
                                    alignItems: 'center', // Centra vertical y horizontalmente
                                }}>
                                <div className="w-[20%] text-end mr-4 font-bold text-xl">Código:</div>
                                <InputField
                                    classExtraName={'rounded-full'}
                                    classNameContainer="w-[70%]"
                                    name={'code'}
                                    placeholder={'Código'}
                                    control={control}
                                    type={'text'}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Requerido',
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-start-5 col-span-4 flex justify-start"
                                style={{
                                    alignItems: 'center', // Centra vertical y horizontalmente
                                }}>
                                {/* <div className="w-[20%] text-end mr-4 font-bold text-xl">Categoría:</div>
                                <InputFieldReactSelect
                                    classNameContainer={'rounded-full w-[70%]'}
                                    name={'category'}
                                    control={control}
                                    placeholder={'Categoría'}
                                    options={categoryOptions}
                                /> */}
                                {
                                    categories?.length == 0 ?
                                        <>
                                            <div className="w-[20%] text-end mr-4 font-bold text-xl">Categoría:</div>
                                            <div>
                                                {<div className="pl-2">Cargando...</div>}
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="w-[20%] text-end mr-4 font-bold text-xl">Categoría:</div>
                                                <InputFieldReactSelect
                                                    classNameContainer={'rounded-full w-[70%]'}
                                                    name={'category'}
                                                    control={control}
                                                    placeholder={'Categoría'}
                                                    options={categoryOptions}
                                                    rules={{
                                                        onChange: (e) => {
                                                            if (e.target.value.value === "Otro"){
                                                                setExistsOtherCategory(true);
                                                            } else {
                                                                setExistsOtherCategory(false);
                                                            }
                                                        },
                                                        required: {
                                                            value: true,
                                                            message: 'Requerido',
                                                        }
                                                    }}
                                                />
                                        </>
                                }
                            </div>
                            <div className="col-start-1 col-span-4 flex justify-start"
                                style={{
                                    alignItems: 'center', // Centra vertical y horizontalmente
                                }}>
                                <div className="w-[20%] text-end mr-4 font-bold text-xl">Descripción:</div>
                                <InputField
                                    classExtraName={'rounded-full'}
                                    classNameContainer="w-[70%]"
                                    name={'description'}
                                    placeholder={'Descripción'}
                                    control={control}
                                    type={'text'}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Requerido',
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-start-5 col-span-4 flex justify-start"
                                style={{
                                    alignItems: 'center', // Centra vertical y horizontalmente
                                }}>
                                <div className="w-[20%] text-end mr-4 font-bold text-xl">Tarifa:</div>
                                <InputField
                                    classExtraName={'rounded-full'}
                                    classNameContainer="w-[70%]"
                                    name={'fee'}
                                    placeholder={'Tarifa'}
                                    control={control}
                                    type={'number'}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Requerido',
                                        }
                                    }}
                                />
                                {/* <InputFieldReactSelect
                                    classNameContainer={'rounded-full w-[70%]'}
                                    name={'fee'}
                                    control={control}
                                    placeholder={'Tarifa'}
                                    options={feeOptions}
                                /> */}
                            </div>
                            {   existsOtherCategory &&
                                <>
                                    <div className="col-start-1 col-span-4 flex justify-start"
                                        style={{
                                            alignItems: 'center', // Centra vertical y horizontalmente
                                        }}>
                                        <div className="w-[30%] text-end mr-4 font-bold text-xl ">Nueva categoría:</div>
                                        <InputField
                                            classExtraName={'rounded-full'}
                                            classNameContainer="w-[60%]"
                                            name={'otherCategory'}
                                            placeholder={'Nueva Categoría'}
                                            control={control}
                                            type={'text'}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Requerido',
                                                }
                                            }}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                        <div className="grid grid-cols-8 px-10">
                            <div className="col-start-1 col-span-4">
                                <div className="font-bold text-xl text-center pb-6 border-solid border-r-2 border-gray-600">Plan: ORO</div>
                                <div className="flex justify-evenly border-solid border-r-2 border-gray-600">
                                    <div className="font-bold text-lg text-center mb-4 text-secondary">Nivel 1</div>
                                    <div className="font-bold text-lg text-center mb-4 text-secondary">Nivel 2</div>
                                </div>
                                <div className="font-bold text-lg text-center pb-4 border-solid border-r-2 border-gray-600">Copago $</div>
                                <div className="flex border-solid border-r-2 border-gray-600">
                                    <InputField
                                        classExtraName={'rounded-full'}
                                        classNameContainer=" mx-auto"
                                        name={'goldPlanLevelOneCopayment'}
                                        placeholder={''}
                                        control={control}
                                        type={'number'}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Requerido',
                                            }
                                        }}
                                    />
                                    <InputField
                                        classExtraName={'rounded-full'}
                                        classNameContainer=" mx-auto"
                                        name={'goldPlanLevelTwoCopayment'}
                                        placeholder={''}
                                        control={control}
                                        type={'number'}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Requerido',
                                            }
                                        }}
                                    />
                                </div>
                                <div className="py-6 px-7 border-solid border-r-2 border-gray-600">
                                    <hr className="bg-gray-600 h-1" />
                                </div>
                                <div className="font-bold text-lg text-center pb-4 border-solid border-r-2 border-gray-600">Coaseguro %</div>
                                <div className="flex border-solid border-r-2 border-gray-600">
                                    <InputField
                                        classExtraName={'rounded-full'}
                                        classNameContainer=" mx-auto"
                                        name={'goldPlanLevelOneCoinsured'}
                                        placeholder={''}
                                        control={control}
                                        type={'number'}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Requerido',
                                            }
                                        }}
                                    />
                                    <InputField
                                        classExtraName={'rounded-full'}
                                        classNameContainer=" mx-auto"
                                        name={'goldPlanLevelTwoCoinsured'}
                                        placeholder={''}
                                        control={control}
                                        type={'number'}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Requerido',
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-start-5 col-span-4">
                                <div className="font-bold text-xl text-center pb-6">Plan: PLATINO</div>
                                <div className="flex justify-evenly">
                                    <div className="font-bold text-lg text-center mb-4 text-secondary">Nivel 1</div>
                                    <div className="font-bold text-lg text-center mb-4 text-secondary">Nivel 2</div>
                                </div>
                                <div className="font-bold text-lg text-center pb-4">Copago $</div>
                                <div className="flex">
                                    <InputField
                                        classExtraName={'rounded-full'}
                                        classNameContainer=" mx-auto"
                                        name={'platinumPlanLevelOneCopayment'}
                                        placeholder={''}
                                        control={control}
                                        type={'number'}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Requerido',
                                            }
                                        }}
                                    />
                                    <InputField
                                        classExtraName={'rounded-full'}
                                        classNameContainer=" mx-auto"
                                        name={'platinumPlanLevelTwoCopayment'}
                                        placeholder={''}
                                        control={control}
                                        type={'number'}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Requerido',
                                            }
                                        }}
                                    />
                                </div>
                                <div className="py-6 px-7">
                                    <hr className="bg-gray-600 h-1" />
                                </div>
                                <div className="font-bold text-lg text-center pb-4">Coaseguro %</div>
                                <div className="flex">
                                    <InputField
                                        classExtraName={'rounded-full'}
                                        classNameContainer=" mx-auto"
                                        name={'platinumPlanLevelOneCoinsured'}
                                        placeholder={''}
                                        control={control}
                                        type={'number'}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Requerido',
                                            }
                                        }}
                                    />
                                    <InputField
                                        classExtraName={'rounded-full'}
                                        classNameContainer=" mx-auto"
                                        name={'platinumPlanLevelTwoCoinsured'}
                                        placeholder={''}
                                        control={control}
                                        type={'number'}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Requerido',
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="mx-auto max-w-6xl flex flex-row justify-between mt-12 pb-24">
                        <button className="btn btn-wide text-lg text-white bg-[#717477] hover:bg-[#5C5F61]"
                            onClick={() => handleBackButton()}>
                            Volver
                        </button>
                        {/* <button className="btn btn-wide text-lg text-white bg-secondary hover:bg-[#3E8B34]"
                        form="addService">
                            Guardar
                        </button> */}
                        <ClassicButton
                            classNameContainer="btn btn-wide rounded-lg bg-secondary hover:bg-[#3E8B34] text-white 
                                text-lg font-semibold mx-4"
                            isSubmit={false}
                            label={'Guardar'}
                            form="addService"
                            isDisabled={!isValid}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
